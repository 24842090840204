import React from 'react'
import { UlupinarBannerProps } from './type'
import { classnames } from 'begonya/functions'
import video from '@/components/base/video'
import Image from '@/components/base/image/Image'
import Button from '@/components/base/button/Button'
import { app } from '@/core/utils/path'
import { useApp } from '@/core/contexts/app'
import Anchor from '@/components/base/anchor/Anchor'
import { Column, Container, Row } from '@/components/base/gridview'

const UlupinarBanner: React.FC<UlupinarBannerProps> = ({
  bannerimage,
  bannercontent = '',
  anchor,
  isButton,
}) => {
  const app = useApp()
  return (
    <div className="UlupinarBanner">
      <div
        className="UlupinarBanner-inner"
        style={{ background: `url('${bannerimage.src}')` }}
      >
        <Container size="medium" className="UlupinarBanner-inner-content">
          <Row>
            <Column
              xs={{ size: 12 }}
              sm={{ size: 12 }}
              md={{ size: 6 }}
              lg={{ size: 6 }}
              xl={{ size: 6 }}
            >
              <div
                className="UlupinarBanner-inner-content-text"
                dangerouslySetInnerHTML={{ __html: bannercontent }}
              />
              {isButton && (
                <Anchor
                  {...anchor}
                  className="UlupinarBanner-inner-content-button"
                />
              )}
            </Column>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default UlupinarBanner
